<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>财务账龄报表</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="visible == ''" class="Enter-top-right"></div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <!-- 报表展示 -->
      <a-layout-content
        class="flex-d-rowa"
        style="width: 100%; height: 158px; min-width: 1366px"
      >
        <div style="width: 256px" class="top-chart">
          <span class="chart-small">总逾期欠款</span>
          <p class="chart-big">{{ (unPay / 10000).toFixed(2) }}万元</p>
          <div style="width: 100%; border-bottom: 1px solid #d9d9d9">
            <a-progress
              :showInfo="false"
              style="width: 220px; margin-top: 20px"
              :percent="100"
              :success-percent="
                (
                  (parseFloat(current.total - current.payed) /
                    parseFloat(currenthistory.total - currenthistory.payed)) *
                  100
                ).toFixed(2)
              "
            />
          </div>
          <span class="small-text">总欠款商户 :</span>
          <span class="small-text1">{{ this.unPayNum }}个</span>
        </div>
        <div style="min-width: 240px" class="top-chart flex-d-row">
          <!-- <div id="chart-mouth"></div> -->
          <a-progress
            type="dashboard"
            :stroke-color="{
              '0%': '#87d068',
              '100%': '#E6A23C',
            }"
            :strokeWidth="10"
            :percent="current.per"
          />
          <div style="margin-top: 28px">
            <span class="chart-small">本月收缴率 :</span>
            <p class="chart-big">{{ current.per }}%</p>
          </div>
        </div>
        <div style="min-width: 315px" class="top-chart">
          <span class="chart-small">应收 :</span>
          <p class="chart-big">{{ current.total }}万元</p>
          <div
            style="width: 100%; height: 40px; border-bottom: 1px solid #d9d9d9"
          >
            <div class="flex-d-rowa" style="padding-right: 24px">
              <span style="font-size: 12px">本月已收</span>
              <a-progress
                style="width: 170px"
                :percent="current.per"
                size="small"
              />
            </div>
            <div class="flex-d-rowa" style="padding-right: 24px">
              <span style="font-size: 12px">本月欠收</span>
              <a-progress
                style="width: 170px"
                :percent="current.payper"
                size="small"
              />
            </div>
          </div>
          <div class="flex-d-rowa">
            <span>
              <span class="small-text">本月已收 :</span>
              <span class="small-text1">{{ current.payed }}万元</span>
            </span>
            <span>
              <span class="small-text">本月欠收 :</span>
              <span class="small-text1"
                >{{ (current.total - current.payed).toFixed(2) }}万元</span
              >
            </span>
          </div>
        </div>
        <div style="min-width: 240px" class="top-chart flex-d-row">
          <!-- <div id="chart-mouth"></div> -->
          <a-progress
            type="dashboard"
            :stroke-color="{
              '0%': '#87d068',
              '100%': '#E6A23C',
            }"
            :strokeWidth="10"
            :percent="currenthistory.per"
          />
          <div style="margin-top: 28px">
            <span class="chart-small">累计收缴率 :</span>
            <p class="chart-big">{{ currenthistory.per }}%</p>
          </div>
        </div>
        <div style="min-width: 315px" class="top-chart">
          <span class="chart-small">应收 :</span>
          <p class="chart-big">{{ currenthistory.total }}万元</p>
          <div
            style="width: 100%; height: 40px; border-bottom: 1px solid #d9d9d9"
          >
            <div class="flex-d-rowa" style="padding-right: 24px">
              <span style="font-size: 12px">累计已收</span>
              <a-progress
                style="width: 170px"
                :percent="currenthistory.per"
                size="small"
              />
            </div>
            <div class="flex-d-rowa" style="padding-right: 24px">
              <span style="font-size: 12px">累计欠收</span>
              <a-progress
                style="width: 170px"
                :percent="currenthistory.payper"
                size="small"
              />
            </div>
          </div>
          <div class="flex-d-rowa">
            <span>
              <span class="small-text">累计已收 :</span>
              <span class="small-text1">{{ currenthistory.payed }}万元</span>
            </span>
            <span>
              <span class="small-text">累计欠收 :</span>
              <span class="small-text1"
                >{{
                  (currenthistory.total - currenthistory.payed).toFixed(2)
                }}万元</span
              >
            </span>
          </div>
        </div>
      </a-layout-content>
      <!-- search -->
      <a-layout-content
        style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        min-width: 1366px
        width: 100%;
      "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row :gutter="24">
            <a-col
              v-for="(i, index) in textlist"
              :key="index"
              :span="6"
              :style="{ display: index + 1 < count ? 'block' : 'none' }"
            >
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '账单类型'"
                  v-model="params['cs.type'].value"
                  style="width: 100%"
                  default-value="0"
                >
                  <a-select-option value="0"> 铺位 </a-select-option>
                  <a-select-option value="1"> 多经点位 </a-select-option>
                  <a-select-option value="2"> 广告位 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  placeholder="请输入客户名称"
                />
              </a-form-item>
            </a-col>
            <a-col :span="6" :style="{ textAlign: 'right', marginTop: '40px' }">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <!-- 主体 -->
      <a-layout-content
        style="background: #fff; padding: 24px; min-width: 1366px; margin: 0"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div class="pag-bot">
            <span v-if="total != 0" style="margin-left: 10px"
              >共<span class="small-text1">{{ total }}</span
              >条账单</span
            >
            <span v-else style="margin-left: 10px">暂无数据</span>
            <span v-show="total != 0" style="margin-left: 20px"
              >全部客户合计欠缴
              <span class="text-bottom"
                >￥{{ (totalfee / 10000).toFixed(2) }}万元</span
              ></span
            >
          </div>
          <a-tabs default-active-key="-1" @change="callback">
            <a-tab-pane key="-1" tab="所有账单">
              <span
                style="position: absolute; top: 12px; left: 76px; color: orange"
                >{{ total }}</span
              >
            </a-tab-pane>
            <a-tab-pane key="0" tab=" ≤30天"
              ><span
                style="
                  position: absolute;
                  top: 12px;
                  left: 180px;
                  color: orange;
                "
                >{{ total }}</span
              ></a-tab-pane
            >
            <a-tab-pane key="1" tab="30天-60天 (含60天)"
              ><span
                style="
                  position: absolute;
                  top: 12px;
                  left: 368px;
                  color: orange;
                "
                >{{ total }}</span
              >
            </a-tab-pane>
            <a-tab-pane key="2" tab=" 60天-120天 (含120天)"
              ><span
                style="
                  position: absolute;
                  top: 12px;
                  left: 580px;
                  color: orange;
                "
                >{{ total }}</span
              >
            </a-tab-pane>
            <a-tab-pane key="3" tab=" >120天"
              ><span
                style="
                  position: absolute;
                  top: 12px;
                  left: 695px;
                  color: orange;
                "
                >{{ total }}</span
              >
            </a-tab-pane>
          </a-tabs>
          <a-table
            rowKey="id"
            @expand="onexpand"
            :pagination="false"
            :expandedRowKeys="expandedRowKeys"
            childrenColumnName="child"
            :columns="modelcolumns"
            :data-source="data"
            class="components-table-demo-nested"
          >
            <span slot="fee" slot-scope="text, record">{{
              record.totalFee - record.payed
            }}</span>
            <span slot="payDate" slot-scope="text">{{ text | date }}天</span>
            <a-table
              rowKey="name"
              slot="expandedRowRender"
              childrenColumnName="child"
              :columns="innerColumns"
              :data-source="innerData"
              :pagination="false"
            >
              <span slot="startDate" slot-scope="text, record"
                >{{ record.startDate }}-{{ record.endDate }}
              </span>
            </a-table>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const modelcolumns = [
  {
    title: '客户名称',
    dataIndex: 'clientName',
  },
  {
    title: '合同编号',
    dataIndex: 'signCode',
  },
  {
    title: '应收日期',
    dataIndex: 'payDate',
  },
  {
    title: '账单金额',
    dataIndex: 'fee',
  },
  {
    title: '欠缴金额',
    dataIndex: 'totalFee',
    scopedSlots: { customRender: 'fee' },
  },
  {
    title: '欠缴时长',
    dataIndex: 'payDate',
    scopedSlots: { customRender: 'payDate' },
  },
]
const innerColumns = [
  { title: '费用名称', dataIndex: 'name', key: 'name' },
  {
    title: '起止时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  { title: '计租面积', dataIndex: 'area' },
  {
    title: '单价',
    dataIndex: 'fee',
  },
  {
    title: '单位',
    dataIndex: 'feeType ',
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
  },
]
// import * as echarts from "echarts";
import http from '../../../http'
import moment from 'moment'
export default {
  data() {
    return {
      modelcolumns,
      innerColumns,
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      textlist: ['客户名称', '账单类型', '欠缴时长'],
      visible: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      params: {
        current: 1,
        pageSize: 10,
        'cc.name': {
          value: '',
          op: '%like%',
        },
        'cs.type': {
          value: '0',
          op: '=',
        },
        time: -1,
      },
      unPayNum: '',
      unPay: '',
      totalfee: '',
      total: 0,
      data: [],
      innerData: [],
      expandedRowKeys: [],
      currenthistory: {
        payed: '',
        total: '',
        per: '',
        payper: '',
      },
      current: {
        payed: '',
        total: '',
        per: '',
        payper: '',
      },
      Id: '',
      form: {
        id: '',
        superiorId: '',
        permissions: '新增',
        path: '',
        name: '',
        type: 0,
        icon: '',
        state: '',
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
    date(date) {
      return moment(moment()).diff(moment(date), 'days')
    },
  },
  methods: {
    callback(e) {
      this.params.current = 1
      this.params.time = parseInt(e)
      this.getzlreport()
    },
    onClose() {
      this.visible = false
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        this.params.time = parseInt(this.time)
        this.params['cc.name'].value = values.客户名称
      })
      this.getzlreport()
    },
    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    onpagesize(e) {
      this.params.current = e
      this.getzlreport()
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      this.form.permissions = JSON.stringify(this.form.permissions)
      if (this.Id == '') {
        this.AddModule(this.form)
      } else if (this.Id != '') {
        this.putModule(this.form)
      }
    },
    onexpand(expanded, record) {
      if (expanded) {
        this.getPaymentInfoId(record.id)
        this.expandedRowKeys = [record.id]
      }
      if (!expanded) {
        this.expandedRowKeys = []
      }
    },
    async getzlreport() {
      try {
        const res = await http.getzlreport(this.params)
        const { success, data } = res.data
        if (success) {
          this.data = data.bills.records
          this.total = data.bills.total
          this.totalfee = data.totalUnPay
          //   console.log(data);
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getPaymentInfoId(id) {
      try {
        const res = await http.getPaymentInfoId(id)
        const { success, data } = res.data
        if (success) {
          this.innerData = data.bills
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getcurrent() {
      try {
        const res = await http.getcurrent()
        const { success, data } = res.data
        if (success) {
          //   console.log(data.report);
          if (data.report.total == null) {
            data.report.total = 0
          }
          if (data.report.payed == null) {
            data.report.payed = 0
          }
          this.current.payed = (data.report.payed / 10000).toFixed(2)
          this.current.total = (data.report.total / 10000).toFixed(2)
          var a = data.report.total - data.report.payed
          this.current.per = (
            (data.report.payed / data.report.total) *
            100
          ).toFixed(2)
          this.current.payper = ((a / data.report.total) * 100).toFixed(2)
          if (data.report.payed == 0 && data.report.total == 0) {
            this.current.payper = 0
            this.current.per = 0
            return
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getcurrenthistory() {
      try {
        const res = await http.getcurrenthistory()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.unPayNum = data.unPayNum
          this.unPay = data.unPay
          if (data.report.total == null) {
            data.report.total = 0
          }
          if (data.report.payed == null) {
            data.report.payed = 0
          }
          this.currenthistory.payed = (data.report.payed / 10000).toFixed(2)
          this.currenthistory.total = (data.report.total / 10000).toFixed(2)
          this.currenthistory.per = (
            (data.report.payed / data.report.total) *
            100
          ).toFixed(2)
          this.currenthistory.payper = (
            ((data.report.total - data.report.payed) / data.report.total) *
            100
          ).toFixed(2)
          if (data.report.payed == 0 && data.report.total == 0) {
            this.currenthistory.payper = 0
            this.currenthistory.per = 0
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getflowAudit()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getcurrent()
    this.getzlreport()
    this.getcurrenthistory()
  },
  computed: {
    count() {
      return this.expand ? 4 : 4
    },
  },
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  border-radius: 3px;
  position: relative;
  min-height: 522px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.small-text {
  font-size: 12px;
  font-weight: 500;
}
.small-text1 {
  font-size: 14px;
  font-weight: 600;
}
.ant-layout {
  height: 100%;
}
.flex-d-rowa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-d-colum {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chart-small {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}
.top-chart {
  height: 128px;
  background-color: #fff;
  padding: 7px 18px;
  margin-bottom: 14px;
  border-radius: 10px;
}
.chart-big {
  font-size: 22px;
  font-weight: 800;
  color: #000;
  margin: 0;
  margin-left: 5px;
  margin-top: -3px;
}
@media screen and (min-width: 1670px) {
  .top-chart {
    width: 18.5%;
  }
}
#chart-mouth {
  height: 100%;
  width: 150px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.Enter-top-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}
.top-right-inpot {
  height: 32px;
  font-size: 13px;
  color: orange;
  border-radius: 22px;
  margin: 0 10px;
  border: 1px solid orange;
}

.pag-bot {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  background-color: #f7f8fc;
  line-height: 30px;
}
.text-bottom {
  color: #0091fe;
  cursor: pointer;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
</style>
